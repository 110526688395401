import axios from 'axios'

const instance = axios.create({

 //baseURL: 'http://localhost:8000/'
 //baseURL: 'https://pespkaobs-backend.ddns.net/',
 baseURL: 'https://pkagis-backend.attica.gov.gr/'
})

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance
